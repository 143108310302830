import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Utilities/seo"
import { Section } from "../components/Utilities/section"
import { Container } from "../components/Utilities/container"

const Template = ({ data }) => {
  const { markdownRemark: post } = data
  // const featuredImage = getImage(post.frontmatter.imagen)
  // const imageSrc = featuredImage.images.fallback.src

  return (
    <>
      <Seo title={post.frontmatter.title} />
      <Section>
        <Container>
          <h1>Proyecto {post.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Container>
      </Section>
    </>
  )
}

export const postQuery = graphql`
  query ($slug: String) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        postType
        path
        date
        title
        imagen {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [WEBP, AVIF])
          }
        }
      }
    }
  }
`

export default Template